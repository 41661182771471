import * as client_hooks from '../../../src/hooks.client.js';
import * as universal_hooks from '../../../src/hooks.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [];

export const dictionary = {
		"/(main)/(payments)": [11,[3]],
		"/(main)/(payments)/(console)/accounts/[ID]": [12,[3,4]],
		"/(main)/(payments)/(console)/accounts/[ID]/confirm": [~13,[3,4]],
		"/(main)/(payments)/(console)/accounts/[ID]/customers": [14,[3,4]],
		"/(main)/(payments)/(console)/accounts/[ID]/customers/[customerID]": [15,[3,4]],
		"/(main)/(payments)/(console)/accounts/[ID]/customers/[customerID]/methods": [16,[3,4]],
		"/(main)/(payments)/(console)/accounts/[ID]/customers/[customerID]/pay": [17,[3,4]],
		"/(main)/(payments)/(console)/accounts/[ID]/customers/[customerID]/pay/[scheduledID]": [18,[3,4]],
		"/(main)/api": [~28,[3]],
		"/(main)/(payments)/beefy": [22,[3]],
		"/(main)/(crypto)/coinbase": [~7,[3]],
		"/(main)/(payments)/(console)/console": [19,[3,4]],
		"/(main)/contact": [29,[3]],
		"/(main)/(crypto)/crypto": [8,[3]],
		"/(main)/(payments)/(console)/customers": [20,[3,4]],
		"/(main)/hilltop": [30,[3]],
		"/(documents)/invoices/[ID]": [5,[2]],
		"/(main)/madison": [31,[3]],
		"/(main)/madison/guests": [32,[3]],
		"/(main)/nav": [33,[3]],
		"/(main)/(tools)/password": [24,[3]],
		"/(main)/(tools)/password/instructions": [25,[3]],
		"/(main)/(legal)/privacy": [9,[3]],
		"/(main)/(tools)/qr": [26,[3]],
		"/(documents)/receipts/[ID]": [6,[2]],
		"/(main)/(payments)/(console)/settings": [21,[3,4]],
		"/(main)/signin": [34,[3]],
		"/(main)/(legal)/terms": [10,[3]],
		"/(main)/(payments)/test": [23,[3]],
		"/(main)/totally": [35,[3]],
		"/(main)/(tools)/upload": [27,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';